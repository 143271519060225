<template lang='pug'>
	#app-container(:class='(me && me.id) ? getMenuType : {}')
		//div(v-if='me && me.id')
			sidebar
			top-nav
		top-nav-lg
		.empty-page-with-header-and-footer.h-100.d-flex.flex-wrap.justify-content-center.align-items-sm-center(:class='{"is-auth" : me}')
			.container.container-fluid(:class='{"container-fluid-payment" : isPaymentPage}')
				router-view
		footer-component(:full-width='true')
		modal-mobile-menu
</template>

<script>
import { mapGetters } from 'vuex'
import TopNav from '@/containers/TopNavShort'
import TopNavMain from '@/containers/TopNav'
import Footer from '@/containers/Footer'
import MobileMenu from '@/components/Modals/MobileMenu'
import Sidebar from '@/containers/Sidebar'

export default {
	components: {
		'top-nav-lg': TopNav,
		'top-nav': TopNavMain,
        'footer-component': Footer,
		'modal-mobile-menu'	: MobileMenu,
		Sidebar
	},
	created() {
		this.viewGeoIpRussia();
	},
	computed: {
		...mapGetters(['getMenuType']),
		isPaymentPage () {
			return this.$route.name == 'payment';
		}
	},
	mounted: function() {
		document.body.classList.add("background");
	},
	beforeDestroy() {
		document.body.classList.remove("background");
	}
}
</script>

<style lang='scss' scoped>
	.container-fluid-payment {
		align-self: self-start;
	}
	.empty-page-with-header-and-footer:not(.is-auth) {
		padding-top: 56px;
		padding-bottom: 66px;
	}

	@media (max-width: 767.98px) {
		.empty-page-with-header-and-footer.is-auth {
			padding-top: 56px;
		}
	}
</style>
