<template lang='pug'>
	.user
		b-dropdown.dropdown-with-content#user-menu(v-if='me' right='' variant='outline-primary' toggle-class='p-0' menu-class='w-100' no-caret='')
			template(slot='button-content')
				span
					img(v-if='me.avatar' :alt='me.tgUsername' :src='me.avatar.contentUrl')
					div.avatar-default.avatar-40(v-else :style='me.avatarBg') {{ me.tgUsername.charAt(0) | uppercase }}
			slot(v-if='me.isPartner')
				b-dropdown-item(@click='offer') {{ $t('h1.offer') }}
			b-dropdown-item(@click='finance') {{ $t('h1.finance') }}
			b-dropdown-item.text-nowrap(@click='help') {{ $t('h1.tutorials') }}
			b-dropdown-item(@click='logout') {{ $t('h1.logout') }}
</template>


<script>
import { mapActions } from 'vuex'

export default {
	name: 'UserDropdown',

	data: () => ({
		
	}),

	components: {
		
	},

	created () {
		
	},

	mounted () {
		
	},
	
	methods: {
		...mapActions({
			signOut : 'auth/logout'
		}),
		logout() { this.signOut().then(() => this.$router.push({name: 'login'})) },
		projects() { if( this.$route.name != 'projects') this.$router.push({name: 'projects'}); },
		offer() { if( this.$route.name != 'offer') this.$router.push({name: 'offer'}); },
		finance() { if( this.$route.name != 'finance') this.$router.push({name: 'finance'}); },
		settings() { if( this.$route.name != 'settings') this.$router.push({name: 'settings'}); },
		help() { if( this.$route.name != 'tutorials') this.$router.push({name: 'tutorials'}); },
	},

	computed: {
		
	}
}
</script>
<style lang='scss'>
	#user-menu {
		.dropdown-menu {
			min-width: 130px;
		}
	}
</style>